import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { OtherSettingsFormSection } from './components/otherSettingsFormSection';
import { ObjectLocationFormSection } from './components/objectLocationFormSection';
import { ServiceSettingsFormSection } from './components/serviceSettingsFormSection';
import { SettingsFormSection } from './components/settingsFormSection';
import { EntryFormSection } from './components/entryFormSection';
import { CallInformationFormSection } from './components';
import { JobAttachments } from '../jobAttachments';
import { fieldNames } from '../fieldNames';
import { FILE_EDIT_STATUSES } from '../constants';

const JobInformation = props => {
    const { job, form, values, reloadBiddingListData } = props;
    const fileUploadEnabled = FILE_EDIT_STATUSES.includes(job.jobStatus);

    return (
        <div className="container-default" style={{ maxWidth: '1500px' }}>
            <div className="row">
                <div className="col-lg-12 col-xl-4">
                    <EntryFormSection updateKeyValue={form.mutators.updateKeyValue} />
                    <SettingsFormSection
                        jobTypes={props.jobTypes}
                        formValues={values}
                        updateKeyValue={form.mutators.updateKeyValue}
                        job={job}
                        reloadBiddingListData={reloadBiddingListData}
                    />
                    <ServiceSettingsFormSection
                        services={props.services}
                        updateKeyValue={form.mutators.updateKeyValue}
                        selectedServiceGroup={values[fieldNames.serviceGroup]}
                    />
                    <ObjectLocationFormSection updateKeyValue={form.mutators.updateKeyValue} />
                </div>
                <div className="col">
                    <OtherSettingsFormSection
                        updateKeyValue={form.mutators.updateKeyValue}
                        jobTypes={props.jobTypes}
                        formValues={values}
                    />
                    {!!job.adminCallInfo && <CallInformationFormSection />}
                </div>
                <div className="col-lg-12 col-xl-4">
                    <JobAttachments
                        photosBefore={job.photosBefore}
                        photosAfter={job.photosAfter}
                        materialsPhotos={job.materialsPhotos}
                        files={job.files}
                        fileUploadSettings={props.fileUploadSettings}
                        fileUploadEnabled={fileUploadEnabled}
                        isJobEditable={props.isJobEditable}
                        isJobFilesEditable={props.isJobFilesEditable}
                        jobScope={job.jobScope}
                        partnerFiles={job.partnerFiles}
                    />
                </div>
            </div>
        </div>
    );
};

JobInformation.propTypes = {
    form: PropTypes.object,
    values: PropTypes.object,
    job: PropTypes.object,
    services: PropTypes.array,
    jobTypes: PropTypes.array,
    dispatch: PropTypes.func,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    fileUploadSettings: PropTypes.object,
    isJobEditable: PropTypes.bool,
    isJobFilesEditable: PropTypes.bool,
    reloadBiddingListData: PropTypes.func
};

function mapStateToProps(state) {
    const { services, jobTypes } = state.jobCreationReducers;

    const { job } = state.jobReducers;

    const { fileUploadSettings } = state.jobCreationReducers;

    const { isJobEditable, isJobFilesEditable } = state.localJobReducers;

    return {
        services,
        jobTypes,
        job: job?.value || {},
        fileUploadSettings,
        isJobEditable,
        isJobFilesEditable
    };
}

const connectedJobInformation = withRouter(connect(mapStateToProps)(JobInformation));
export { connectedJobInformation as JobInformation };
