import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { Switch } from 'antd';
import { Classificator, FormField, formFieldType, Translate } from '../../../../components';
import { fieldNames } from '../../fieldNames';
import { getCurrentDate, i18, i18cl, translate } from '../../../../utilities';
import { isJobFactual, isJobStandard, isJobFixed } from '../../../jobsCreate';
import { JobsStatusConstants } from '../../../../api';

import OfferModal from './offerModal';

class SettingsFormSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.onJobTypeSwitched = this.onJobTypeSwitched.bind(this);
        this.isEditableAndNotFactual = this.isEditableAndNotFactual.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.isOfferGenerationEnabled = this.isOfferGenerationEnabled.bind(this);
    }

    componentDidUpdate() {
        const { jobTypes } = this.props;

        if (jobTypes && jobTypes.length === 2) {
            const jobType = jobTypes[1];
            if (this.props.formValues.jobType === undefined) {
                this.props.updateKeyValue(fieldNames.jobType, jobType);
            }
        }
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    onJobTypeSwitched(isChecked) {
        const { jobTypes } = this.props;
        const jobType = isChecked ? jobTypes[0] : jobTypes[1];

        this.props.updateKeyValue(fieldNames.jobType, jobType);
    }

    isEditableAndNotFactual() {
        return (
            this.props.isJobEditable && (this.isPreorderAndFixed() || this.isApprovedInProgressPausedAndStandardFixed())
        );
    }

    isPreliminaryPricesEditable() {
        return this.props.isJobEditable && this.props.formValues.jobStatus === JobsStatusConstants.PREORDER;
    }

    isEditableAndResolved() {
        return false;
    }

    isEditableAndNotResolved() {
        return this.props.isJobEditable && this.props.formValues.jobStatus !== JobsStatusConstants.RESOLVED;
    }

    isApprovedInProgressPausedAndStandardFixed() {
        return (
            this.isApprovedInProgressOrPaused() &&
            (isJobStandard(this.props.formValues.biddingType) || isJobFixed(this.props.formValues.biddingType))
        );
    }

    isApprovedInProgressOrPaused() {
        return (
            this.props.formValues.jobStatus === JobsStatusConstants.APPROVED ||
            this.props.formValues.jobStatus === JobsStatusConstants.INPROGRESS ||
            this.props.formValues.jobStatus === JobsStatusConstants.PAUSED
        );
    }

    isPreorderAndFixed() {
        return (
            this.props.formValues.jobStatus === JobsStatusConstants.PREORDER &&
            isJobFixed(this.props.formValues.biddingType)
        );
    }

    shouldShowPrices(biddingType, status) {
        return (
            !isJobFactual(biddingType) ||
            JobsStatusConstants.RESOLVED === status ||
            JobsStatusConstants.COMPLETED === status
        );
    }

    isOfferGenerationEnabled() {
        const { isJobEditable } = this.props;
        const { biddingType, jobStatus, isPreorderSentToPartners } = this.props.formValues;

        if (isJobEditable && isJobStandard(biddingType)) {
            if (jobStatus === JobsStatusConstants.NEW) {
                return isPreorderSentToPartners;
            }
            return jobStatus === JobsStatusConstants.PREORDER;
        }

        return false;
    }

    isStandardOrFreetext(biddingType) {
        return isJobStandard(biddingType) || (isJobFixed(biddingType) && !this.props.formValues.hasServiceItem);
    }

    calculateTotal = (_value, values) => {
        const clientPrice = Number(values[fieldNames.clientPrice]);
        const partnerReward = Number(values[fieldNames.partnerReward]);
        const administrationFee = Number(values[fieldNames.administrationFee]);
        const materialExpenses = Number(values[fieldNames.materialExpenses]);
        const total = Number((partnerReward + administrationFee + materialExpenses).toFixed(2));

        if (clientPrice !== total) {
            return translate(i18.Job.Errors.PriceValidation);
        }
    };

    render() {
        const { biddingType, jobStatus, factualHours, isHandymanMaterials } = this.props.formValues;

        return (
            <>
                <Translate className="fs-16 fw_600" value={i18.Job.Titles.Settings} />
                <div className="container-default mt-4">
                    {this.shouldShowPrices(biddingType, jobStatus) && (
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.PreliminaryPartnerReward}
                                        borderTitle
                                        name={fieldNames.preliminaries.preliminaryPartnerReward}
                                        component={formFieldType.number}
                                        validate={this.calculateTotal}
                                        disabled={!this.isPreliminaryPricesEditable()}
                                        highlighted={this.isPreliminaryPricesEditable()}
                                        dimmed={this.props.isJobEditable && !this.isPreliminaryPricesEditable()}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.PreliminaryMaterialsPrice}
                                        borderTitle
                                        name={fieldNames.preliminaries.preliminaryMaterialsPrice}
                                        validate={this.calculateTotal}
                                        component={formFieldType.number}
                                        disabled={!this.isPreliminaryPricesEditable()}
                                        highlighted={this.isPreliminaryPricesEditable()}
                                        dimmed={this.props.isJobEditable && !this.isPreliminaryPricesEditable()}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.ClientPrice}
                                        borderTitle
                                        component={formFieldType.number}
                                        name={fieldNames.clientPrice}
                                        validate={this.calculateTotal}
                                        disabled={!this.isEditableAndNotFactual()}
                                        highlighted={this.isEditableAndNotFactual()}
                                        dimmed={this.props.isJobEditable && !this.isEditableAndNotFactual()}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.PartnerReward}
                                        borderTitle
                                        name={fieldNames.partnerReward}
                                        validate={this.calculateTotal}
                                        component={formFieldType.number}
                                        disabled={!this.isEditableAndNotFactual()}
                                        highlighted={this.isEditableAndNotFactual()}
                                        dimmed={this.props.isJobEditable && !this.isEditableAndNotFactual()}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.AdministrationFee}
                                        borderTitle
                                        name={fieldNames.administrationFee}
                                        validate={this.calculateTotal}
                                        component={formFieldType.number}
                                        disabled={!this.isEditableAndNotFactual()}
                                        highlighted={this.isEditableAndNotFactual()}
                                        dimmed={this.props.isJobEditable && !this.isEditableAndNotFactual()}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.MaterialExpenses}
                                        borderTitle
                                        name={fieldNames.materialExpenses}
                                        component={formFieldType.number}
                                        disabled={!this.isEditableAndNotFactual()}
                                        highlighted={this.isEditableAndNotFactual()}
                                        dimmed={this.props.isJobEditable && !this.isEditableAndNotFactual()}
                                    />
                                </div>
                                {this.isOfferGenerationEnabled() && (
                                    <div className="col-6">
                                        <a onClick={this.toggleModal}>
                                            <Translate value={i18.Job.Labels.BiddingOffer} />
                                        </a>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {isHandymanMaterials && (
                        <div className="row mb-4">
                            <div className="col-1">
                                <img src="../../images/help.svg" alt="help" />
                            </div>
                            <div className="col-11">
                                <Translate
                                    className="mr-1 fs-13 text-secondary"
                                    value={
                                        this.isStandardOrFreetext(biddingType, jobStatus)
                                            ? i18.Job.Labels.MaterialsPriceShouldBeIncluded
                                            : i18.Job.Labels.MaterialsPriceIncluded
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        {factualHours && (
                            <div className="col-6">
                                <FormField
                                    title={i18.Job.Titles.FactualDuration}
                                    borderTitle
                                    name={fieldNames.factualHours}
                                    component={formFieldType.input}
                                    disabled
                                    dimmed={this.props.isJobEditable}
                                />
                            </div>
                        )}
                        <div className={`col-6 d-flex ${factualHours ? 'mt-4' : 'offset-md-6'}`}>
                            <Translate
                                className={`mr-1 ${this.props.isJobEditable ? 'ant-input-dimmed' : 'text-secondary'}`}
                                value={i18.Job.Labels.JobType}
                            />
                            <Field name={fieldNames.jobType}>
                                {fieldProps => (
                                    <Switch
                                        name={fieldProps.input.name}
                                        className="flex-grow-1 float-right"
                                        checked={fieldProps.input.value === this.props.jobTypes[0]}
                                        checkedChildren={
                                            <Classificator value={i18cl.TYPES_WORK + this.props.jobTypes[0]} />
                                        }
                                        unCheckedChildren={
                                            <Classificator value={i18cl.TYPES_WORK + this.props.jobTypes[1]} />
                                        }
                                        onChange={this.onJobTypeSwitched}
                                        disabled
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.BiddingPeriod}
                                borderTitle
                                name={fieldNames.biddingDateRange}
                                component={formFieldType.dateRangePicker}
                                placeholderFrom={i18.JobCreateForm.Labels.BiddingStartDate}
                                placeholderTo={i18.JobCreateForm.Labels.BiddingEndDate}
                                time
                                disabled
                                dimmed={this.props.isJobEditable}
                                minDate={{
                                    date: getCurrentDate(0)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <OfferModal
                    showModal={this.state.showModal}
                    toggleModal={this.toggleModal}
                    jobId={this.props.formValues.jobId}
                    serviceId={this.props.formValues.serviceId}
                    availableVats={this.props.job.availableVats}
                    availableMaterialVats={this.props.job.availableMaterialVats}
                    vatName={this.props.job.vatName}
                    materialsVatName={this.props.job.materialsVatName}
                    vatType={this.props.job.vatType}
                    materialsVatType={this.props.job.materialsVatType}
                    reloadBiddingListData={this.props.reloadBiddingListData}
                />
            </>
        );
    }
}

SettingsFormSection.propTypes = {
    formValues: PropTypes.object.isRequired,
    isJobEditable: PropTypes.bool.isRequired,
    jobTypes: PropTypes.array,
    updateKeyValue: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    job: PropTypes.object,
    reloadBiddingListData: PropTypes.func
};

function mapStateToProps(state) {
    const { isJobEditable } = state.localJobReducers;

    return {
        isJobEditable
    };
}

const connectedSettingsFormSection = connect(mapStateToProps)(SettingsFormSection);
export { connectedSettingsFormSection as SettingsFormSection };
