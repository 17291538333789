import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18, routerPaths } from '../../utilities';
import { ContentHeader, Translate } from '../../components';
import { JobsCreateForm } from '.';

class JobsCreateContainer extends React.Component {
    render() {
        return (
            <div>
                <ContentHeader
                    title={<Translate value={i18.JobsCreate.Titles.JobsCreate} />}
                    back={routerPaths.jobs.index}
                    backState={this.props.history.location.state}
                />
                <div className="content_block">
                    <JobsCreateForm />
                </div>
            </div>
        );
    }
}

JobsCreateContainer.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    })
};

function mapStateToProps(state) {
    return {};
}

const connectedJobsCreateContainer = connect(mapStateToProps)(JobsCreateContainer);
export { connectedJobsCreateContainer as JobsCreateContainer };
